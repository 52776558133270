import { Injectable } from "@angular/core";

import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  success(message, title?) {
    this.toastr.success(message, title);
  }

  error(message, title?) {
    if (sessionStorage.getItem("username")) this.toastr.error(message, title);
  }

  errorWithNoTimeout(message, title?) {
    this.toastr.error(message, title, {
      timeOut: 0, // No timeout, stays until manually closed
      closeButton: true, // Shows a close button
      progressBar: true, // Optional: Adds a progress bar (though it won't run with timeOut: 0)
      tapToDismiss: false, // Optional: Prevents auto-dismiss when clicked
    });
  }

  info(message, title?) {
    this.toastr.info(message, title);
  }

  warning(message, title?) {
    this.toastr.warning(message, title);
  }
}
