import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { LanguageConfig } from "src/app/Language-config";
import { ClientControlService } from "../client-control.service";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatSort } from "@angular/material/sort";
import { ConfirmationDialogService } from "src/app/service/confirmation-dialog.service";

@Component({
  selector: "app-view-clients",
  templateUrl: "./view-clients.component.html",
  styleUrls: ["./view-clients.component.scss"],
})
export class ViewClientsComponent implements OnInit {
  public isLoading: boolean;
  public hasResult: boolean;
  public currentHide: boolean = true;

  displayedColumns = [
    "id",
    "orgName",
    "email",
    "name",
    "createdDate",
    "totalEmployees",
    "totalActiveEmployees",
    "totalUsers",
    "totalActiveUsers",
    "lastActivity",
    "status",
    "actions",
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  public requestForm: FormGroup;
  lang: Map<string, string>;

  constructor(
    private fb: FormBuilder,
    private clientContrlService: ClientControlService,
    private notifySrvc: NotificationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.lang = LanguageConfig.map;
  }

  ngOnInit(): void {
    this.createForm();
  }

  // //for status sorting
  // ngAfterViewInit() {
  //   console.log(" aaa");
  //   this.dataSource.sortingDataAccessor = (item, property) => {
  //     switch (property) {
  //       case "status": {
  //         return item[property];
  //       }
  //       default: {
  //         return item[property];
  //       }
  //     }
  //   };
  // }

  public createForm(): void {
    this.requestForm = this.fb.group({
      securityCode: "",
      clientQuery: "",
      active: true,
    });
  }

  public listClients() {
    this.isLoading = true;
    this.clientContrlService.getAll(this.requestForm.value).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.data) {
          this.hasResult = true;
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.notifySrvc.error(err || "Error in list clients");
      }
    );
  }

  public async deleteClient(clientId: string) {
    const confirmed = await this.confirmationDialogService.openConfirmationDialog(
      "Confirmation",
      "Are you sure you want to delete the client " + clientId + " ?"
    );

    if (confirmed) {
      this.isLoading = true;
      const body = {
        securityCode: this.requestForm.value.securityCode,
        toDelete: clientId,
      };
      this.clientContrlService.delete(body).subscribe(
        (res) => {
          if (res) {
            for (let msg of res.data.success) {
              this.notifySrvc.success(msg);
            }
            this.notifySrvc.success(res.msg);
            this.listClients();
          }
        },
        (error) => {
          for (let msg of error.error.data.success) {
            this.notifySrvc.success(msg);
          }
          for (let msg of error.error.data.fails) {
            this.notifySrvc.errorWithNoTimeout(msg);
          }
          const err = error.error && error.error.msg;
          this.notifySrvc.errorWithNoTimeout(err || "Error in delete client");
          this.isLoading = false;
          this.listClients();
        }
      );
    }
  }

  public showLastActivities(clientId: string) {}
}
